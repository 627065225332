import api from "../api";
import { AxiosResponse } from "axios";

const alliancesBaseUrl = "alliances";

class AlliancesService {
  fetchCompanies = (companyName: string): Promise<AxiosResponse<any>> => {
    return api.get(`${alliancesBaseUrl}/companies`, {
      params: { search: companyName },
    });
  };

  fetchClients = (): Promise<AxiosResponse<any>> => {
    return api.get(`${alliancesBaseUrl}/clients`);
  };

  fetchClientOrigins = (sales_source: string): Promise<AxiosResponse<{origins: string[]}>> => {
    return api.get(`${alliancesBaseUrl}/clients/origins`, {params: {sales_source}});
  }
}

const alliancesService = new AlliancesService();
export default alliancesService;
